import { useState } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { ENDPOINTS } from '../config';

export default function useTournament(key) {
  const { fetcher } = useSWRConfig();
  const [upsertError, setUpsertError] = useState(null);
  const [upsertLoading, setUpsertLoading] = useState(null);

  const { data, error, loading, mutate } = useSWR(key ? `${ENDPOINTS.TOURNAMENTS}/${key}?` : {});

  const handleRequest = async (config) => {
    setUpsertLoading(true);
    setUpsertError(null);
    return fetcher(config)
      .then((res) => {
        setUpsertLoading(false);
        mutate(res?.data);
        return res?.data;
      })
      .catch((error) => {
        if (error.response.data.details?.length > 0) {
          setUpsertError(error.response.data.details);
        } else {
          setUpsertError([error.response.data]);
        }
        setUpsertLoading(false);
        throw error;
      });
  };

  const updateTournament = async (data) => {
    return handleRequest({
      url: `${ENDPOINTS.TOURNAMENTS}/${key}`,
      method: 'put',
      data,
    }).then((e) => e);
  };

  const createTournament = async (data) => {
    return handleRequest({
      url: `${ENDPOINTS.TOURNAMENTS}`,
      method: 'post',
      data,
    }).then((e) => e);
  };

  // const updateChallengeRequirements = async (data) => {
  //   return handleRequest({
  //     url: `${ENDPOINTS.CHALLENGES}/${key}/requirements`,
  //     method: 'patch',
  //     data,
  //   });
  // };

  return {
    tournament: data?.data || {},
    error: error || upsertError,
    loading,
    upsertLoading: upsertLoading,
    mutateTournament: mutate,
    updateTournament,
    createTournament,
  };
}
