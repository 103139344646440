import { extend, map, range } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GenericActions from '../../actions/genericActions';
import LeaderboardDesktop from '../../components/Leaderboard/desktop';
import Header from '../Header';
import actions from './actions';
import useRegions from '../../hooks/useRegions';

const RegisteredTeamLeaderboardPage = ({ leaderboard, getLeaderboard }) => {
  const [year, setYear] = useState(moment().format('Y'));
  const [region, setRegion] = useState(1);
  const years = map(range(2018, moment().format('Y') * 1 + 1), (y) => y.toString());

  const { regions } = useRegions();

  useEffect(() => {
    if (!leaderboard.latestEvent) {
      const query = { startDate: `${year}-01-01`, endDate: `${year}-12-31`, regionId: region };
      getLeaderboard(query);
    }
  }, []);

  const onChangeYear = (updateYear) => {
    if (updateYear !== year) {
      setYear(updateYear);
      const query = { startDate: `${updateYear}-01-01`, endDate: `${updateYear}-12-31`, regionId: region };
      // console.log('query', query);
      getLeaderboard(query);
    }
  };

  const onChangeRegion = (updateRegion) => {
    if (updateRegion !== region) {
      setRegion(updateRegion);
      const query = { startDate: `${year}-01-01`, endDate: `${year}-12-31`, regionId: updateRegion };
      // console.log('query', query);
      getLeaderboard(query);
    }
  };

  return (
    <div className="page-container">
      <Header currentPage="/teams" />
      <div className="container-fluid">
        <div className="content-box">
          <h3>Leaderboard</h3>
          <select onChange={(e) => onChangeYear(e.target.value)}>
            {years.map((y) => (
              <option key={y} value={y} selected={y === year}>
                {y}
              </option>
            ))}
          </select>

          <select onChange={(r) => onChangeRegion(r.target.value)}>
            {regions.map((r) => (
              <option key={r.id} value={r.id}>
                {r.name}
              </option>
            ))}
          </select>
          <LeaderboardDesktop latestEventDateTime={leaderboard.latestEvent} leaderboard={leaderboard.standings} />
        </div>
      </div>
    </div>
  );
};

RegisteredTeamLeaderboardPage.propTypes = {
  getLeaderboard: PropTypes.func.isRequired,
  leaderboard: PropTypes.objectOf(PropTypes.shape),
};
RegisteredTeamLeaderboardPage.defaultProps = {
  leaderboard: {},
};

const mapStateToProps = (state) => extend({}, state.registeredTeams, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(RegisteredTeamLeaderboardPage);
