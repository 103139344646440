/*
 * API Configurations
 */
export const API_URL = {
  V1: process.env.REACT_APP_API_URL || 'http://localhost:4000/v1',
};

export const ENDPOINTS = {
  LOGIN: `${API_URL.V1}/login`,
  PROFILE: `${API_URL.V1}/profile`,
  RESET_PASSWORD: `${API_URL.V1}/reset-password`,
  VALIDATE: `${API_URL.V1}/vaildate`,
  REGISTER: `${API_URL.V1}/register`,

  EVENTS: `${API_URL.V1}/events`,
  QUICK_CREATE_ENDPOINT: `${API_URL.V1}/quick-create-events`,
  EVENT_TEMPLATES: `${API_URL.V1}/event-templates`,
  HOSTS: `${API_URL.V1}/hosts`,
  USERS: `${API_URL.V1}/users`,
  ACCOUNTS: `${API_URL.V1}/accounts`,
  BILLING_ACCOUNTS: `${API_URL.V1}/billing-accounts`,
  REGIONS: `${API_URL.V1}/regions`,
  PAYMENTS: `${API_URL.V1}/payments`,
  REGISTERED_TEAMS: `${API_URL.V1}/teams`,
  TOURNAMENTS: `${API_URL.V1}/tournaments`,
  TOURNAMENT_INVITES: `${API_URL.V1}/tournament-invites`,
  LOCATIONS: `${API_URL.V1}/locations`,
  HOST_APPLICATIONS: `${API_URL.V1}/host-applications`,
  LEADS: `${API_URL.V1}/leads`,
  LIVE_STREAM: `${API_URL.V1}/live-streams`,
  // LIVE_STREAM_RESPONSE: `${API_URL.V1}/live-stream-responses`,

  REPORTING: `${API_URL.V1}/reports`,

  LEADERBOARD: `${API_URL.V1}/leaderboards`,
};

// A helpful way to keep track of error CODES/messages
export const ERRORS = {
  NOT_LOGGED_IN: 'You are not logged in yet!',
};

export const DEFAULT_ERROR_MSG = 'Something went wrong!';

export const DEFAULT_SUCCESS_MSG = 'The operation was completed successfully!';
