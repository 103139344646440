import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import useTeams from '../../hooks/useRegisteredTeams';

const RegisteredTeamSelector = ({ isLoading, onChange, isClearable, selectedTeam }) => {
  const { teams, updateFilterState } = useTeams();

  const onInputChange = (inputValue) => {
    updateFilterState({ search: inputValue });
  };
  return (
    <>
      <Select
        classNamePrefix="select"
        getOptionValue={(opt) => opt.id}
        getOptionLabel={(opt) => `[${opt.teamUsername}] - ${opt.name}`}
        isClearable={isClearable}
        isLoading={isLoading}
        value={teams.filter(({ id }) => id === selectedTeam)}
        onChange={onChange}
        options={teams}
        onInputChange={onInputChange}
      />
    </>
  );
};

RegisteredTeamSelector.propTypes = {
  isLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape),
  selectedTeam: PropTypes.number,
  isClearable: PropTypes.bool,
};
RegisteredTeamSelector.defaultProps = {
  isLoading: false,
  selectedTeam: null,
  isClearable: true,
};

export default RegisteredTeamSelector;
