import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

const TournamentEventSelector = ({ isLoading, onChange, list, selectedTournament, isClearable }) => {
  return (
    <>
      <Select
        classNamePrefix="select"
        getOptionValue={(opt) => opt.id}
        getOptionLabel={(opt) => `${opt.name} - ${moment(opt.dateTimeStart).format('MM/DD/YYYY')}`}
        isClearable={isClearable}
        isLoading={isLoading}
        // value={selectedTournament}
        value={list.filter(({ id }) => id === selectedTournament)}
        onChange={onChange}
        options={list}
        // onInputChange={onInputChange}
      />
    </>
  );
};

TournamentEventSelector.propTypes = {
  isLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape),
  selectedTournament: PropTypes.number,
  isClearable: PropTypes.bool,
};
TournamentEventSelector.defaultProps = {
  isLoading: false,
  selectedTournament: null,
  isClearable: true,
};

export default TournamentEventSelector;
