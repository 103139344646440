import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import Pagination from 'rc-pagination';
import { Link } from 'react-router-dom';
import { Table, Button } from 'reactstrap';
import { AlertCircle, CheckCircle } from 'react-feather';
import Edit from 'react-feather/dist/icons/edit';
// import User from 'react-feather/dist/icons/user-plus';
import Spinner from '../Spinner';

const LIMITS = [20, 50, 100];
class TournamentsTable extends Component {
  constructor() {
    super();
    this.state = {
      filters: {
        isActive: true,
      },
    };
  }

  setIsActiveFilter = (val) => {
    const { filters } = this.state;
    filters.isActive = val;
    this.setState({ filters }, this.updateList);
  };

  updateList = () => {
    const { filters } = this.state;
    const { onFilter } = this.props;
    onFilter(filters.isActive);
  };

  render() {
    const { filters } = this.state;
    const { onSearch, isFailed, isLoading, list, pagination, onPageNumberChange, onPageSizeChange, errorMessage, isSuccess, onToggleActive } =
      this.props;
    return (
      <div>
        {/* <Link to="/teams/create">Create New Team</Link> */}
        {/* <div>Pagination{JSON.stringify(pagination)}</div> */}
        <div className="row">
          <div className="col-sm-4">
            <input type="text" placeholder="Search..." className="form-control" onChange={(e) => onSearch(e.target.value)} />
          </div>
          <div className="col-sm-8">
            <Button
              disabled={filters.isActive === null}
              className="status-button mb-2"
              color={filters.isActive === null ? 'primary' : 'link'}
              size="sm"
              onClick={() => this.setIsActiveFilter(null)}
            >
              All
            </Button>
            <Button
              disabled={filters.isActive === true}
              className="status-button mb-2"
              color={filters.isActive === true ? 'primary' : 'link'}
              size="sm"
              onClick={() => this.setIsActiveFilter(true)}
            >
              Active Only
            </Button>
            <Button
              disabled={filters.isActive === false}
              className="status-button mb-2"
              color={filters.isActive === false ? 'primary' : 'link'}
              size="sm"
              onClick={() => this.setIsActiveFilter(false)}
            >
              Inactive Only
            </Button>
          </div>
        </div>
        <Table striped hover responsive size="sm" className="element-list">
          <thead>
            <tr>
              <th>Name</th>
              <th>Key</th>
              <th className="text-center">Active</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {isFailed && (
              <tr>
                <td colSpan="4" className="danger">
                  {errorMessage}
                </td>
              </tr>
            )}
            {isLoading && (
              <tr>
                <td colSpan="4">
                  <Spinner />
                </td>
              </tr>
            )}
            {/* {JSON.stringify(list)} */}
            {isSuccess &&
              list &&
              map(list, (item) => (
                <tr key={item.id}>
                  <td>
                    <Link to={`/tournaments/${item.key}`} className="action">
                      {item.name}
                    </Link>
                  </td>
                  <td>{item.key}</td>
                  <td className="text-center">
                    {item.isActive ? (
                      <span className="clickable-avatar" title="Active" onClick={() => onToggleActive(item, false)}>
                        <CheckCircle color="green" />
                      </span>
                    ) : (
                      <span className="clickable-avatar" title="Inactive" onClick={() => onToggleActive(item, true)}>
                        <AlertCircle color="red" />
                      </span>
                    )}
                  </td>
                  <td className="actions">
                    <Link to={`/tournaments/${item.key}/edit`} className="action">
                      <Edit color="#999" size={15} />
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="row query-footer">
          <div className="page-size form-inline col-sm-6">
            <select defaultValue={pagination.pageSize} className="form-control input-sm" onChange={(e) => onPageSizeChange(e.target.value)}>
              {map(LIMITS, (opt) => (
                <option key={opt} value={opt}>
                  {opt}
                </option>
              ))}
            </select>
            {' per page'}
          </div>
          <div className="col-sm-6 text-right">
            {pagination && (
              <Pagination
                onChange={onPageNumberChange}
                showLessItems
                // current={1}
                // total={5}
                // pageSize={20}
                defaultPageSize={50}
                current={pagination.pageNumber}
                total={pagination.totalCount}
                pageSize={pagination.pageSize || 20 || 20}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

TournamentsTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape),
  pagination: PropTypes.objectOf(PropTypes.shape),
  onPageSizeChange: PropTypes.func.isRequired,
  onPageNumberChange: PropTypes.func.isRequired,
  onToggleActive: PropTypes.func.isRequired,

  onSearch: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,

  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isFailed: PropTypes.bool,
  errorMessage: PropTypes.string,
};
TournamentsTable.defaultProps = {
  isLoading: false,
  isSuccess: false,
  isFailed: false,
  errorMessage: '',
  list: [],
  pagination: {
    pageNumber: 1,
    pageSize: 20,
    totalCount: 1000,
  },
};

export default TournamentsTable;
