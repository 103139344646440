import { useState } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { ENDPOINTS } from '../config';

export default function useTournamentInvite(id) {
  const { fetcher } = useSWRConfig();
  const [upsertError, setUpsertError] = useState(null);
  const [upsertLoading, setUpsertLoading] = useState(null);

  const { data, error, loading, mutate } = useSWR(id ? `${ENDPOINTS.TOURNAMENT_INVITES}/${id}` : {});

  const handleRequest = async (config) => {
    setUpsertLoading(true);
    setUpsertError(null);
    return fetcher(config)
      .then((res) => {
        setUpsertLoading(false);
        mutate(res?.data);
        return res?.data;
      })
      .catch((error) => {
        if (error.response.data.details?.length > 0) {
          setUpsertError(error.response.data.details);
        } else {
          setUpsertError([error.response.data]);
        }
        setUpsertLoading(false);
        throw error;
      });
  };

  const updateInvite = async (data) => {
    return handleRequest({
      url: `${ENDPOINTS.TOURNAMENT_INVITES}/${data.id}`,
      method: 'put',
      data,
    }).then((e) => e);
  };

  const createInvite = async (data) => {
    return handleRequest({
      url: `${ENDPOINTS.TOURNAMENT_INVITES}`,
      method: 'post',
      data,
    }).then((e) => e);
  };

  const sendInvite = async (id) => {
    return handleRequest({
      url: `${ENDPOINTS.TOURNAMENT_INVITES}/${id}/send`,
      method: 'post',
      data,
    }).then((e) => e);
  };

  return {
    invite: data?.data || {},
    error: error || upsertError,
    loading,
    upsertLoading: upsertLoading,
    mutateTeam: mutate,
    updateInvite,
    createInvite,
    sendInvite,
  };
}
