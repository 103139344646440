import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import Spinner from '../Spinner/index';
import Placement from '../Placement/index';
import './style.css';

const LeaderboardDesktop = ({ leaderboard, latestEventDateTime, currentTeamId, hideHeader, className }) => {
  if (!leaderboard || leaderboard.length < 1) return <Spinner />;
  return (
    <div>
      {!hideHeader && latestEventDateTime !== '' && (
        <div className="last-update">Last Updated: {moment(latestEventDateTime.dateTimeStart).format('MMM Do, YYYY')}</div>
      )}
      <table className={`leaderboard-table table-striped ${className}`}>
        {!hideHeader && (
          <thead>
            <tr>
              <th className="text-center">Rank</th>
              <th>Team</th>
              <th>Username</th>
              <th className="text-right">WT Points</th>
            </tr>
          </thead>
        )}
        <tbody>
          {leaderboard.map((team) => (
            <tr key={team.registeredTeamId} className={currentTeamId && currentTeamId === team.registeredTeamId ? 'current-team' : ''}>
              <td>
                <Placement position={team.rank} className="no-left-margin" />
              </td>
              <td>
                <Link to={`/teams/${team.registeredTeamId}`}>{team.registeredTeam.name}</Link>
              </td>
              <td>{team.registeredTeam.teamUsername}</td>
              <td className="text-right">
                {team.points}
                pts
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
LeaderboardDesktop.propTypes = {
  leaderboard: PropTypes.arrayOf(PropTypes.shape),
  latestEventDateTime: PropTypes.objectOf(PropTypes.shape),
  currentTeamId: PropTypes.number,
  hideHeader: PropTypes.bool,
  className: PropTypes.string,
};
LeaderboardDesktop.defaultProps = {
  leaderboard: [],
  latestEventDateTime: {},
  currentTeamId: null,
  hideHeader: false,
  className: '',
};

export default LeaderboardDesktop;
