import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'reactstrap';
import * as Yup from 'yup';
import useTournament from '../../hooks/useTournament';
import useTournamentInvite from '../../hooks/useTournamentInvite';
import RegisteredTeamSelector from '../RegisteredTeams/RegisteredTeamSelector';
import TournamentEventSelector from './TournamentEventSelector';
import { toast } from 'react-toastify';

const InviteTeamForm = ({ onSubmit, tournamentKey }) => {
  const { tournament, loading } = useTournament(tournamentKey);

  const { createInvite } = useTournamentInvite();
  const {
    handleSubmit,
    control,
    reset,
    register,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        // email: Yup.string().email('Invalid email address').required('Email is required'),
        eventId: Yup.number().required('Event is required'),
        cost: Yup.number().required('Cost is required'),
      }),
    ),
  });
  const addRegisteredTeam = (team) => {
    const teamsList = getValues('teamsList');
    if (teamsList.length > 0 && teamsList.find((t) => t.id === team.id)) {
      return alert('Team already added');
    }
    setValue('teamsList', [...teamsList, team]);
  };

  const teamsListWatch = watch('teamsList');
  const costWatch = watch('cost');

  const removeRegisteredTeam = (team) => {
    const teamsList = getValues('teamsList');
    setValue(
      'teamsList',
      teamsList.filter((t) => t.id !== team.id),
    );
  };
  const presubmit = async (data) => {
    // console.log('presubmit', data);
    const filteredData = { ...data, teamsList: data.teamsList.map((t) => t.id) };

    const team = {
      tournamentId: tournament.id,
      eventId: filteredData.eventId,
      cost: filteredData.cost,
    };
    await Promise.all(filteredData.teamsList.map((teamId) => createInvite({ ...team, registeredTeamId: teamId })));

    onSubmit();

    toast('Team Invites Created', {
      type: toast.TYPE.SUCCESS,
    });
    // reset();
  };
  return (
    <form onSubmit={handleSubmit(presubmit)}>
      <div className="row mb-4">
        <div className="col-4">
          <div className="form-group">
            {/* <div>Values: {JSON.stringify(teamsListWatch)}</div> */}
            <input type="hidden" {...register('teamsList')} />
            <label htmlFor="cost">Event</label>
            {tournament?.events?.length > 0 ? (
              // <TournamentEventSelector list={tournament.events} onChange={(o) => setValue('eventId', o.id)} isClearable={false} isLoading={loading} />
              <Controller
                name="eventId"
                control={control}
                required
                render={({ field }) => (
                  <TournamentEventSelector
                    {...field}
                    list={tournament.events}
                    onChange={(o) => field.onChange(o.id)}
                    selectedTournament={field.value}
                    isClearable={false}
                    isLoading={loading}
                  />
                )}
              />
            ) : (
              <div>No events added to tournament</div>
            )}
            {errors.eventId && <div className="alert alert-danger">Please select an event</div>}
          </div>
        </div>
        <div className="col-4">
          <div className="form-group">
            <label htmlFor="cost">Cost for Team Entry</label>
            <input type="number" className="form-control" {...register('cost')} />
            {errors.cost && <div className="alert alert-danger">Cost is required</div>}
          </div>
        </div>
        <div className="col-4">
          <div className="form-group">
            <label htmlFor="cost">Team</label>
            <RegisteredTeamSelector list={tournament.events} onChange={(o) => addRegisteredTeam(o)} isClearable={false} isLoading={loading} />
          </div>
        </div>
        {teamsListWatch?.length > 0 && (
          <div className="col-12">
            <h3>Invites to send</h3>
            <div className="form-group">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Team</th>
                    <th>Team Cost</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {teamsListWatch &&
                    teamsListWatch.map &&
                    teamsListWatch?.map((t) => (
                      <tr key={t.id}>
                        <td>
                          {t.name} ({t.teamUsername})
                        </td>
                        <td>${costWatch.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) || 0}</td>
                        <td>
                          <Button
                            onClick={() => {
                              removeRegisteredTeam(t);
                            }}
                            className="btn btn-danger"
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="col-12 d-flex justify-content-end">
              <Button onClick={() => reset()} disabled={teamsListWatch?.length <= 0} className="btn mr-4">
                Reset
              </Button>
              <button type="submit" className="btn btn-success" disabled={teamsListWatch?.length <= 0}>
                Create Invites
              </button>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

InviteTeamForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  tournamentKey: PropTypes.string.isRequired,
};

InviteTeamForm.defaultProps = {};

export default InviteTeamForm;
